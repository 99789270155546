<script>
import Form from '@/utils/form'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import { mapActions } from 'vuex'
import pick from 'lodash/pick'

export default {
    mixins: [SnackbarMixin],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        service: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: new Form({
                title: null,
                short_description: null,
                link: null,
                icon: null
            })
        }
    },
    computed: {
        formTitle() {
            return this.service ? 'Edit button' : 'Create new button'
        }
    },
    watch: {
        value() {
            if (this.value) {
                this.form.$reset()
                if (this.service) {
                    Object.assign(
                        this.form,
                        pick(this.service, [
                            'title',
                            'short_description',
                            'link',
                            'icon'
                        ])
                    )
                }
            }
        }
    },
    methods: {
        ...mapActions('service', ['createService', 'updateService']),
        submit() {
            this.form.$busy = true
            let action = null

            if (this.service) {
                action = 'updateService'
            } else {
                action = 'createService'
            }
            const fd = this.form.$formData(['icon'])
            if (this.form.icon instanceof File) {
                fd.append('icon', this.form.icon)
            }

            this[action](fd)
                .then(() => {
                    this.$emit('input', false)
                    this.$emit('success')
                    this.form.$reset()
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        this.form.$setErrors(err.response.data.errors)
                    } else {
                        this.showSnackbar(err.response.data.message, 'error')
                    }
                    this.form.$busy = false
                })
                .finally(() => {
                    this.form.$busy = false
                })
        },
        cancel() {
            this.$emit('input', false)
        }
    }
}
</script>

<template>
    <v-dialog @input="$emit('input', $event)" :value="value" max-width="500">
        <v-card>
            <v-card-title class="headline">{{ formTitle }}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="justify-center align-center">
                        <v-col cols="12">
                            <v-form ref="form">
                                <label class="text-field-label">Icon</label>
                                <v-file-input
                                    :clearable="false"
                                    accept="image/*"
                                    v-model="form.icon"
                                    placeholder="Select icon..."
                                    :error-messages="form.$getError('icon')"
                                ></v-file-input>
                                <label class="text-field-label">Title</label>
                                <v-text-field
                                    flat
                                    outlined
                                    solo
                                    required
                                    class="mt-2"
                                    v-model="form.title"
                                    :error-messages="form.$getError('title')"
                                ></v-text-field>
                                <label class="text-field-label">Link</label>
                                <v-text-field
                                    flat
                                    outlined
                                    solo
                                    required
                                    class="mt-2"
                                    v-model="form.link"
                                    :error-messages="form.$getError('link')"
                                ></v-text-field>
                                <label class="text-field-label"
                                    >Short Description</label
                                >
                                <v-text-field
                                    flat
                                    outlined
                                    solo
                                    class="mt-2"
                                    v-model="form.short_description"
                                    :error-messages="
                                        form.$getError('short_description')
                                    "
                                ></v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn text @click="cancel()" :disabled="form.$busy"
                    >Cancel</v-btn
                >
                <v-btn
                    color="primary"
                    :loading="form.$busy"
                    @click="submit()"
                    >{{ service ? 'Save' : 'Add' }}</v-btn
                >
            </v-card-actions>
            <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
                {{ snackbar.message }}
                <v-btn text @click="snackbar.show = false">OK</v-btn>
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>
