<script>
import Draggable from 'vuedraggable'
import { mapState, mapActions, mapMutations } from 'vuex'
import {
    mdiMagnify,
    mdiAccountCircleOutline,
    mdiPlus,
    mdiPencil,
    mdiDelete
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import ServiceDialog from '@/views/Home/Services/components/ServiceDialog.vue'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ConfirmDialog from '@/components/modals/ConfirmDialog.vue'

export default {
    name: 'Services',

    mixins: [ControlsMixin, SnackbarMixin],

    directives: {
        infiniteScroll
    },

    components: {
        ConfirmDialog,
        ServiceDialog,
        AppBarNavIcon,
        Draggable
    },

    data() {
        return {
            unAuthorizedMessage: '',
            loading: false,
            showSearch: false,
            filter: {
                search: ''
            },
            icons: {
                search: mdiMagnify,
                view: mdiAccountCircleOutline,
                plus: mdiPlus,
                edit: mdiPencil,
                delete: mdiDelete
            }
        }
    },

    created() {
        this.clearServices()
        this.fetchServices(1)
    },

    computed: {
        ...mapState({
            listMeta: state => state.service.listMeta
        }),
        ...mapState('service', ['deleting']),
        services: {
            get() {
                return this.$store.state.service.list
            },
            set(value) {
                this.$store.commit('service/reorderServiceList', value)
            }
        },
        dialogShown: {
            get: function() {
                return this.$store.state.service.dialogShown
            },
            set: function(value) {
                this.$store.commit('service/setDialogShown', value)
            }
        },
        confirmDeleteShown: {
            get: function() {
                return this.$store.state.service.confirmDeleteShown
            },
            set: function(value) {
                this.$store.commit('service/setConfirmDeleteShown', value)
            }
        },
        deletingService: {
            get: function() {
                return this.$store.state.service.deletingService
            },
            set: function(value) {
                this.$store.commit('service/setDeletingService', value)
            }
        },
        editingService: {
            get: function() {
                return this.$store.state.service.editingService
            },
            set: function(value) {
                this.$store.commit('service/setEditingService', value)
            }
        },
        canReorder() {
            return !this.filter.search
        }
    },

    methods: {
        ...mapActions({
            getServices: 'service/getServices',
            deleteService: 'service/deleteService',
            rearrangeServicesSequence: 'service/rearrangeServicesSequence'
        }),

        ...mapMutations({
            clearServices: 'service/clearServiceList'
        }),

        pageChanged(page) {
            this.fetchServices(page)
        },

        loadMore() {
            if (this.listMeta.current_page < this.listMeta.last_page) {
                this.fetchServices(this.listMeta.current_page + 1)
            }
        },

        search: debounce(function() {
            this.clearServices()
            this.fetchServices()
        }, 600),

        async fetchServices(page = 1) {
            if (this.loading) return
            let params = { page }
            if (this.filter.search) {
                params.search = this.filter.search
            }
            this.loading = true
            this.getServices(params)
                .then(res => {
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 403) {
                        this.unAuthorizedMessage = err.response.data.message
                    }
                })
        },

        create() {
            this.editingService = false
            this.dialogShown = true
        },

        confirmDelete(service) {
            this.deletingService = service
            this.confirmDeleteShown = true
        },

        edit(service) {
            this.editingService = service
            this.dialogShown = true
        },

        async handleOrderChange() {
            await this.rearrangeServicesSequence()
            this.showSnackbar('Buttons order updated successfully.', 'success')
        },

        handleSuccess() {
            this.dialogShown = false
            this.showSnackbar('Button has been saved successfully.', 'success')
        }
    }
}
</script>

<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <v-toolbar-title v-else class="headline font-weight-bold"
                >App Buttons ({{ services.length }})</v-toolbar-title
            >

            <v-spacer></v-spacer>
            <v-row v-if="showSearch" align="center" style="max-width: 300px">
                <v-text-field
                    flat
                    hide-details
                    solo
                    rounded
                    clearable
                    background-color="grey lighten-3"
                    label="Search name"
                    v-model="filter.search"
                    @input="search"
                ></v-text-field>
            </v-row>

            <v-skeleton-loader
                type="actions"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <template v-else>
                <v-btn class="ml-5" icon @click="showSearch = !showSearch">
                    <img src="@/assets/icons/search.svg" alt height="17.7px" />
                </v-btn>
            </template>
        </v-app-bar>

        <div
            class="mt-12 mx-4"
            id="service-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
        >
            <v-col class="text-right">
                <v-btn
                    class="mx-2"
                    right
                    fab
                    dark
                    color="primary"
                    @click="create()"
                >
                    <v-icon dark> {{ icons.plus }} </v-icon>
                </v-btn>
            </v-col>
            <v-simple-table>
                <thead>
                    <tr>
                        <th style="width: 50px;"></th>
                        <th style="width: 100px;"></th>
                        <th>
                            Name
                        </th>
                        <th style="width: 200px">Date Created</th>
                        <th style="min-width: 110px;" class="text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <Draggable
                    v-model="services"
                    group="services"
                    tag="tbody"
                    handle=".handle"
                    @change="handleOrderChange()"
                >
                    <tr v-for="service in services" :key="service.id">
                        <td>
                            <v-tooltip
                                bottom
                                color="warning"
                                :disabled="canReorder"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <a
                                        href="#"
                                        @click.prevent
                                        :class="{ handle: canReorder }"
                                        style="cursor: grab;"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <img
                                            src="@/assets/icons/reorder.svg"
                                            alt
                                        />
                                    </a>
                                </template>
                                <span
                                    >You are not allowed to reorder the buttons
                                    when searching.</span
                                >
                            </v-tooltip>
                        </td>
                        <td>
                            <img
                                :src="service.icon.url"
                                alt="Icon"
                                width="64"
                            />
                        </td>
                        <td>
                            <span class="font-weight-bold">
                                {{ service.title }} </span
                            ><br />
                            <small>{{ service.short_description }}</small>
                        </td>
                        <td>
                            {{ service.created_at | formatDatetime }}
                        </td>
                        <td class="py-5 text-center">
                            <v-btn
                                dark
                                color="primary"
                                icon
                                class="d-inline"
                                @click="edit(service)"
                            >
                                <v-icon dark>
                                    {{ icons.edit }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                dark
                                color="primary"
                                icon
                                class="d-inline"
                                @click="confirmDelete(service)"
                            >
                                <v-icon dark>
                                    {{ icons.delete }}
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </Draggable>
            </v-simple-table>
        </div>

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
        </v-snackbar>

        <ServiceDialog
            v-model="dialogShown"
            :service="editingService"
            @success="handleSuccess"
        />
        <ConfirmDialog
            v-model="confirmDeleteShown"
            @confirm="deleteService()"
            confirm-text="Yes, Delete"
            confirm-color="error"
            :loading="deleting"
        >
            Are you sure you want to delete this button?
        </ConfirmDialog>
    </div>
</template>
