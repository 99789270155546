<template>
    <v-dialog @input="$emit('input', $event)" :value="value" max-width="500">
        <v-card>
            <v-card-title class="headline">Confirmation</v-card-title>
            <v-card-text>
                <slot />
            </v-card-text>
            <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn text @click="$emit('input', false)" :disabled="loading"
                    >Cancel</v-btn
                >
                <v-btn
                    :color="confirmColor"
                    :loading="loading"
                    @click="$emit('confirm')"
                >
                    {{ confirmText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        confirmText: {
            type: String,
            default: 'Confirm'
        },
        confirmColor: {
            type: String,
            default: 'primary'
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
