const SnackbarMixin = {
    data() {
        return {
            snackbar: {
                show: false,
                message: null,
                color: ''
            }
        }
    },
    methods: {
        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.show = true
            this.snackbar.color = color
        }
    }
}

export default SnackbarMixin
